<template>
  <div>
    <div class="condition">
      <!--            <div  style="width: 246px;margin-bottom: 20px">-->
      <!--                -->
      <!--            </div>-->
      <div class="condition_item">
        <el-input
          style="width: 246px;margin-right: 10px"
          v-model.trim="keywordsStr"
          placeholder="请输入客户名称或关键字"
        ></el-input>
        <span>保存时间 </span>
        <el-date-picker
          prefix-icon="el-icon-date"
          v-model="startTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择开始日期时间"
        >
        </el-date-picker>
        <span style="padding:4px">-</span>
        <el-date-picker
          prefix-icon="el-icon-date"
          v-model="endTimes"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择结束日期时间"
        >
        </el-date-picker>
        <el-button
          class="button_default"
          style="margin-left: 10px"
          @click="Query(true)"
          >查询</el-button
        >
        <el-button class="import" icon="el-icon-upload2" @click="importDatas"
          >导出</el-button
        >
        <el-button type="danger" @click="remove()">删除</el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        stripe
        :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="客户名称">
          <template slot-scope="scope">{{ scope.row.customerName }}</template>
        </el-table-column>
        <el-table-column prop="deviceCode" label="设备编码"> </el-table-column>
        <el-table-column prop="assetCode" label="资产编码"> </el-table-column>
        <el-table-column prop="deviceRfId" label="deviceRfId">
        </el-table-column>
        <el-table-column prop="deviceImei" label="IMEI"> </el-table-column>
        <el-table-column label="保存时间">
          <template slot-scope="scope">
            {{ scope.row.productTime | timeFormatting }}
            <!--                        <el-button type="text" style="color: #F56C6C;font-size: 14px" size="small" @click="getClient">删除</el-button>-->
          </template>
        </el-table-column>
        <el-table-column
          prop="assetCode"
          v-if="
            func.getRoot(
              dataList.rootList.find(el => el.name === '未入库编辑').value
            )
          "
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              class="edit el-icon-edit"
              size="small"
              @click="compile(scope.row)"
              >编辑</el-button
            >

            <!--                        <el-button type="text" style="color: #F56C6C;font-size: 14px" size="small" @click="getClient">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="app-page-container">
      <span @click="first()">首页</span>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="pager"
        :hide-on-single-page="false"
        :page-size="pageSize"
        :pager-count="9"
        :current-page="currentPage"
        :total="recordCount"
      >
      </el-pagination>
      <span @click="last()">尾页</span>
    </div>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form label-position="right" label-width="80px" :model="compileData">
        <el-form-item label="客户名称">
          <el-select
            style="width: 100%"
            v-model.number="customerID"
            placeholder="请选择"
          >
            <el-option
              v-for="item in customer"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备编码">
          <el-input v-model="compileData.deviceCode"></el-input>
        </el-form-item>
        <el-form-item label="资产编码">
          <el-input v-model="compileData.assetCode"></el-input>
        </el-form-item>
        <el-form-item label="IMEI">
          <el-input disabled v-model="compileData.deviceImei"></el-input>
        </el-form-item>
        <el-form-item label="RfId">
          <el-input v-model="compileData.deviceRfId"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  AssetManagement,
  deletes,
  redact,
  clientName,
  exportData
} from "../../api/AssetManagement";
export default {
  name: "noStorageDevice",
  data() {
    return {
      customerID: null,
      customer: [],
      keywordsStr: "",
      customerName: "",
      compileData: {},
      dialogVisible: false,
      exportExcelUrl:
        window.baseUrl +
        "/clodchainAM/web/batch_export_asset?isInWareHouse=2&" +
        "token=" +
        localStorage.getItem("token") +
        "&userId=" +
        localStorage.getItem("userId"),
      startTime: null,
      endTimes: null,
      pages: 1,
      pageSize: 12, //每页数据条数
      recordCount: 0,
      currentPage: 1, //当前页数
      tableData: [],
      multipleSelection: []
    };
  },
  created() {
    this.Query(false);
    this.getClient();
  },
  methods: {
    //导出
    importDatas() {
      this.$store.commit("SET_LOADING", true);
      let ids = [];
      if (this.keywordsStr !== "") {
        ids = this.keywordsStr
          .replace(/\s*/g, "")
          .split(/[\uff0c|,]/)
          .filter(item => item !== "")
          .map(item => {
            return item.replace(/\s*/g, "");
          });
      }
      if (this.multipleSelection.length !== 0) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          ids.push(this.multipleSelection[i].deviceImei);
        }
      }
      let data;
      data = {
        isInWareHouse: 2,
        deviceCode: this.customerName,
        deptId: "", //部门ID
        status: "",
        onlineStatus: this.onlineStatus,
        assetYear: this.timeSpan,
        startPutAreaTime: "",
        endPutAreaTime: "",
        keywordsStr: ids.join(","),
        startCreateTime: this.startTime,
        endCreateTime: this.endTimes,
        customerName: "",
        token: localStorage.getItem("token"),
        userId: localStorage.getItem("userId")
      };
      exportData(data)
        .then(res => {
          let _this = this;
          const dt = new Date();
          const y = dt.getFullYear();
          const m = (dt.getMonth() + 1 + "").padStart(2, "0");
          const d = (dt.getDate() + "").padStart(2, "0");
          const hh = (dt.getHours() + "").padStart(2, "0");
          const mm = (dt.getMinutes() + "").padStart(2, "0");
          const ss = (dt.getSeconds() + "").padStart(2, "0");
          let date = y + m + d + hh + mm + ss.toString();
          const content = res.data; //返回的内容
          if (res.data.type !== "application/vnd.ms-excel") {
            //二进制转字符串
            let reader = new FileReader();
            reader.addEventListener("loadend", function(e) {
              // 输出字符串 转化为json对象
              let str = JSON.parse(e.target.result);
              _this.$message({
                showClose: true,
                message: str.desc,
                type: "error"
              });
              if (str.resultCode == 2000) {
                _this.$router.push("/login");
              }
            });
            reader.readAsText(content);
          } else {
            const fileName = "未入库设备列表" + date + ".xls"; //下载文件名
            //处理下载流
            const blob = new Blob([content]); //创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
            const url = window.URL.createObjectURL(blob); //URL.createObjectURL(object)表示生成一个File对象或Blob对象
            let dom = document.createElement("a"); //设置一个隐藏的a标签，href为输出流，设置download
            dom.style.display = "none";
            dom.href = url;
            dom.setAttribute("download", fileName); //指示浏览器下载url,而不是导航到它；因此将提示用户将其保存为本地文件
            document.body.appendChild(dom);
            dom.click();
          }
        })
        .finally(() => {
          this.$store.commit("SET_LOADING", false);
        });
    },
    //获取客户列表
    getClient() {
      clientName({}).then(res => {
        this.customer = res.data.data;
      });
    },
    //对话框
    handleClose(done) {
      done();
    },
    //查询
    Query(type) {
      let data = {
        compileData: {},
        limit: 12,
        curPage: this.pages,
        userId: localStorage.getItem("userId"),
        deviceCode: this.customerName,
        deptId: "", //部门ID
        status: "",
        onlineStatus: this.onlineStatus,
        isInWareHouse: 2,
        assetYear: this.timeSpan,
        startPutAreaTime: "",
        endPutAreaTime: "",
        keywordsStr: this.keywordsStr
          .replace(/\s*/g, "")
          .split(/[\uff0c|,]/)
          .filter(item => item !== "")
          .map(item => {
            return item.replace(/\s*/g, "");
          })
          .join(","),
        customerName: "",
        startCreateTime: this.startTime,
        endCreateTime: this.endTimes,
        multipleSelection: []
      };
      if (type) {
        data.curPage = 1;
      }
      AssetManagement(data).then(res => {
        this.tableData = res.data.data.records;
        this.recordCount = res.data.data.total;
        // this.pages = res.data.data.pages
      });
    },
    //编辑
    compile(data) {
      this.compileData = data;
      data.customerId = parseInt(data.customerId);
      this.dialogVisible = true;
      this.customerID = data.customerId;
    },
    //编辑保存
    save() {
      this.compileData.customerId = this.customerID;
      redact(this.compileData).then(res => {
        this.dialogVisible = false;
        this.Query();
      });
    },
    //分页首页
    first() {
      this.tableData = [];
      this.currentPage = 1;
      this.Query();
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.Query();
    },
    // handleSizeChange(val) {
    //     // console.log(`每页 ${val} 条`);
    // },
    handleCurrentChange(val) {
      this.pages = val;
      this.Query();
      // console.log(`当前页: ${val}`);
    },
    //表格多选框
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    tableRowClassName(row, rowIndex) {
      if (rowIndex == 0) return "headerStyle";
    },
    //删除
    remove() {
      let datas = { ids: [] };
      if (this.multipleSelection.length != 0) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          datas.ids.push(this.multipleSelection[i].deviceImei);
        }
        deletes(datas, "/clodchainAM/web/delete_asset_info", "post").then(
          res => {
            if (res.data.resultCode == 0) {
              this.$message({
                message: "删除成功！",
                type: "success",
                duration: 500,
                onClose: this.Query()
              });
            } else {
              this.$message({
                showClose: true,
                message: "删除失败",
                duration: 500,
                type: "error"
              });
            }
          }
        );
      }
    }
  }
};
</script>

<style scoped>
.edit {
  font-size: 14px;
  color: #6e7790;
}

.edit:hover {
  color: #0cc;
}
.import {
  color: #6e7790;
  background: #f1f1f4;
  border-color: #c5c9d3;
}
.import:hover {
  background: #6e7790;
  border-color: #6e7790;
  color: #fff;
}
.import:focus {
  background: #6e7790;
  border-color: #6e7790;
  color: #fff;
}
.button_default {
  color: #0cc;
  border-color: #99ebeb;
}
.button_default:hover {
  color: #fff;
  border-color: #b3f0f0;
  background-color: #0cc;
}
.button_default:focus {
  color: #fff;
  border-color: #b3f0f0;
  background-color: #0cc;
}
/*查询输入框样式*/
condition_item_input {
  width: 246px !important;
}
.condition {
  margin-bottom: 20px;
  /*display: flex;*/
  /*justify-content: flex-end;*/
}
/*分页样式*/
.app-page-container {
  margin-top: 20px;
}
.app-page-container .el-pagination {
  display: inline-block;
}
.app-page-container span {
  cursor: pointer;
  color: #616161;
  background: #fff;
  border: 1px solid #e8eaf0;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  transition: 0.1s;
  vertical-align: top;
  border-radius: 16px;
  font-size: 12px;
  width: 56px;
}
.app-page-container span:hover {
  background: #00cccc;
  color: #fff;
  border-color: #00cccc;
}
/*表格换行样式*/
/deep/ .el-table .cell {
  white-space: pre-line;
}
</style>
